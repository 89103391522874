import { Main } from "../../components";

export const Divulgacoes = () => {
  return (
    <Main>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Divulgações</h2>
          </div>
        </div>
      </section>
      <section id="budget-management-report" className="section-bg">
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div id="index-composition" className="services">
              <div className="container padding-top-bottom" data-aos="fade-up">
                <div className="row">
                  <div
                    className="col-xl-3 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img
                          className="pdf-icon"
                          src="/assets/img/pdf-icon.png"
                          alt=""
                        />
                      </div>
                      <h4>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1bVovPPkz0lThtlrjt50CRfiLfBROUWYo"
                          rel="noreferrer"
                        >
                          Comprovação de divulgação (PDLIE) e execução
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img
                          className="pdf-icon"
                          src="/assets/img/pdf-icon.png"
                          alt=""
                        />
                      </div>
                      <h4>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1rorVYroyvOrYT6tyW3gvvncvit8DA3_y"
                          rel="noreferrer"
                        >
                          Divulgação no site Araxá Agora
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img
                          className="pdf-icon"
                          src="/assets/img/pdf-icon.png"
                          alt=""
                        />
                      </div>
                      <h4>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1G3_OPNZdnr5ZUb79Dm7YQmnf7_RqG-bM"
                          rel="noreferrer"
                        >
                          Divulgação no site TV Araxá 1
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img
                          className="pdf-icon"
                          src="/assets/img/pdf-icon.png"
                          alt=""
                        />
                      </div>
                      <h4>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1vc0JbVTbZm7VPBCM5t530cLUUeTA2tHV"
                          rel="noreferrer"
                        >
                          Divulgação no site TV Araxá 2
                        </a>
                      </h4>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay="100"
                  >
                    <div className="icon-box pdf-icon-box d-flex align-items-center flex-column">
                      <div className="icon">
                        <img
                          className="pdf-icon"
                          src="/assets/img/pdf-icon.png"
                          alt=""
                        />
                      </div>
                      <h4>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1G4qvYKxfwtpHiszBaPMQj0rUuWgLFd7I/"
                          rel="noreferrer"
                        >
                          Divulgação nas Redes Sociais
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </Main>
  );
};
