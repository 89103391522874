import { Link } from "../../../router/links";
import { MenuSearch, MenuSearchMob } from "./menu-search";

export const TopMenu = () => {
  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <a href={Link.home} className="logo me-auto">
          <div className="img-float">
            <img src="/assets/img/logo.jpg" alt="" className="img-fluid" />
          </div>
        </a>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <a className="nav-link scrollto" href={Link.home}>
                Página inicial
              </a>
            </li>

            <li>
              <a className="nav-link scrollto" href={Link.noticias}>
                Notícias
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href={Link.projetos}>
                Projetos
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="/#portfolio">
                Galeria
              </a>
            </li>
            <li className="dropdown">
              <a href={Link.transparencia}>
                <span>Transparência</span>{" "}
                <i className="bi bi-chevron-down"></i>
              </a>
              <ul>
                <li>
                  <a href={Link.estrutura}>Estrutura</a>
                </li>
                <li>
                  <a href={Link.diretoria}>Diretoria</a>
                </li>
                <li>
                  <a href={Link.divulgacoes}>Divulgações</a>
                </li>
                <li>
                  <a href={Link.regimentoInterno}>Regimento Interno</a>
                </li>
                <li>
                  <a href={Link.taekwondo}>Taekwondo</a>
                </li>
                <li>
                  <a href={Link.atendimento}>Atendimento</a>
                </li>
                <li className="dropdown">
                  <a href={Link.financeiro}>
                    <span>Financeiro</span>{" "}
                    <i className="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li>
                      <a href={Link.financeiro + "#balance-sheets"}>
                        Balancetes
                      </a>
                    </li>
                    <li>
                      <a href={Link.financeiro + "#patrimony-balance-sheet"}>
                        Balanço Patrimonial
                      </a>
                    </li>
                    <li>
                      <a href={Link.financeiro + "#index-composition"}>
                        Composição de Índices
                      </a>
                    </li>
                    <li>
                      <a href={Link.orcamentos}>
                        Orçamentos
                      </a>
                    </li>
                    <li>
                      <a href={Link.financeiro + "#payments-statement"}>
                        <p>
                          Demonstrativo de Pagamento <br />
                          de Beneficiários
                        </p>
                      </a>
                    </li>
                    <li>
                      <a href={Link.financeiro + "#dre"}>DRE</a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href={Link.execucaoDeProjetos.home}>
                    <span>Execução de Projetos</span>
                    <i className="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li>
                      <a href={Link.execucaoDeProjetos.relatorios}>
                        Relatório de Execução
                      </a>
                    </li>
                    <li>
                      <a href={Link.execucaoDeProjetos.publicacoes}>
                        Publicações
                      </a>
                    </li>
                    <li>
                      <a href={Link.execucaoDeProjetos.termosDeCompromisso}>
                        Termos de Compromisso
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href={Link.compras.home}>
                    <span>Compras</span> <i className="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li>
                      <a href={Link.compras.editalDeContratacao}>
                        <p>
                          Edital de Contratação
                          <br />
                          Recursos Humanos
                        </p>
                      </a>
                    </li>
                    <li>
                      <a href={Link.compras.licitacoes}>Licitação</a>
                    </li>
                    <li>
                      <a href={Link.compras.notasFiscais}>Notas Fiscais</a>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <a href={Link.estatutos.home}>
                    <span>Estatutos e Assembleias</span>
                    <i className="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li>
                      <a href={Link.estatutos.home + "#minutes"}>Atas</a>
                    </li>
                    <li>
                      <a href={Link.estatutos.home + "#notice"}>Edital</a>
                    </li>
                    <li>
                      <a href={Link.estatutos.home + "#statute"}>Estatuto</a>
                    </li>
                    <li>
                      <a href={Link.estatutos.home + "#tax-council-opinion"}>
                        Parecer Conselho Fiscal
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href={Link.perguntasERespostas}>
                    Perguntas e Respostas Frequentes
                  </a>
                </li>
                <li className="dropdown">
                  <a href={Link.relatorioDeGestao}>
                    <span>Relatório de Gestão e Execução Orçamentária</span>
                    <i className="bi bi-chevron-right"></i>
                  </a>
                  <ul>
                    <li>
                      <a
                        href={
                          Link.relatorioDeGestao + "#budget-management-report"
                        }
                      >
                        Relatório de Gestão Financeira
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          Link.relatorioDeGestao +
                          "#technical-management-report"
                        }
                      >
                        Relatório de Gestão Técnica
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href={Link.legislacao}>
                    Legislação, Portaria e Resoluções
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="nav-link scrollto" href="/#contact">
                Contato
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href={Link.pesquisa}>
                <MenuSearch />
              </a>
            </li>
            <li>
              <div></div>
            </li>
          </ul>
          <MenuSearchMob>
            <a className="nav-link scrollto" href={Link.pesquisa}>
              <MenuSearch />
            </a>
          </MenuSearchMob>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
};
