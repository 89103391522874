
export const Link =({
  home:'/',
  noticias : '/noticias',
  projetos : '/projetos',
  estrutura : '/estrutura',
  diretoria : '/diretoria',
  divulgacoes : '/divulgacoes',
  orcamentos : '/orcamentos',
  regimentoInterno : '/regimento-interno',
  taekwondo : '/taekwondo',
  atendimento : '/atendimento',  
  financeiro : '/financeiro',
  execucaoDeProjetos : {
    home: '/execucao-de-projetos',
    relatorios: `/relatorios-de-execucoes`,
    publicacoes: `/publicacoes`,
    termosDeCompromisso: `/termos-de-compromisso`,
  },
  compras : {
    home: '/compras',
    editalDeContratacao : '/edital-de-contratacao',
    licitacoes : '/licitacoes',
    notasFiscais : '/notas-fiscaiss',
  },
  estatutos:{
    home: '/estatutos-e-assembleias'
  },
  perguntasERespostas: '/perguntas-e-respostas',
  relatorioDeGestao: '/relatorio-de-gestao',
  legislacao: '/legislacao',
  sobre: '/sobre',
  transparencia: '/transparencia',
  pesquisa: '/pesquisa',
})